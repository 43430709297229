/**
 * Provides individual card image.
 * @param cardType The type of credit card.
 * @returns The image URL for the corresponding credit card type.
 * @remarks Some card types are networked together and have only one image, ex: DISCOVER|JCB|UNIONPAY
 * @category Utils
 */
export const getCreditCardImage = (cardType) => {
  switch (cardType.toUpperCase()) {
    case 'VISA':
      return '/img/credit-cards/visa.svg'
    case 'MAESTRO':
      return '/img/credit-cards/maestro.svg'
    case 'MC':
    case 'MASTERCARD':
      return '/img/credit-cards/mastercard.svg'
    case 'AMEX':
      return '/img/credit-cards/amex.svg'
    case 'DISCOVER':
    case 'UNIONPAY':
      return '/img/credit-cards/discover.svg'
    case 'JCB':
      return '/img/credit-cards/jcb.svg'
    case 'DINERS':
      return '/img/credit-cards/diners.svg'
  }
}
